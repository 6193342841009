
import Vue from "vue";

import {
  userMapActions,
  userMapGetters,
  userMapMutations
} from "@/store/modules/user";
import { authMapGetters } from "@/store/modules/auth";
import UserEditFields from "@/components/User/UserEditFields.vue";
import { objectDifference } from "@/helpers";
import Password from "@/components/User/Password.vue";
import { adminUser } from "@/forms/admin/user";
import { agencyAdminUser } from "@/forms/agencyAdmin/user";
import { get, omit } from "lodash";
import { FormBlock } from "@/components/FormBuilder/types";
import { IUserRestricted } from "@/types";
import { restrictedUser } from "@/forms/admin/restrictUser";
import UsersMixin from "@/components/User/UsersMixin.vue";
// import { getFieldsFromBlocks } from "@/helpers/validateSchema";

interface IData {
  showPassword: boolean;
  disabled: boolean;
  disablePrimaryButton: boolean;
  customKey: number;
  nextRoute: any;
  loadingText: string;
  restrictedData?: IUserRestricted;
  modalTitle?: string;
  modalDescription?: string;
  actionType?: string;
  fields: FormBlock[];
  loading: boolean;
}

export default Vue.extend({
  name: "SingleUserEdit",
  mixins: [UsersMixin],
  components: {
    UserEditFields,
    Password,
    saveChanges: () => import("@/components/Dialog/saveChanges.vue")
  },
  data(): IData {
    return {
      showPassword: false,
      disabled: false,
      disablePrimaryButton: false,
      customKey: 0,
      nextRoute: "",
      loadingText: "",
      loading: false,
      actionType: "",
      fields: restrictedUser
    };
  },
  async beforeRouteLeave(to, from, next): Promise<void> {
    const hasProfileUpdates =
      Object.keys(this.updatedFields) &&
      Object.keys(this.updatedFields).length > 0;
    if (
      hasProfileUpdates &&
      (this.$isCurrentUserAdmin || this.$isCurrentUserAgencyAdmin) &&
      ["restrict", "derestrict"].includes(this.actionType as string)
    ) {
      this.nextRoute = next;
      this.$modal.show("discard-changes-modal");
    } else {
      return next();
    }
  },
  methods: {
    ...userMapActions(["updateUser"]),
    ...userMapMutations({
      editField: "SET_USER_EDIT_FIELD"
    }),
    async update(beforeRouteLeave?: boolean): Promise<void> {
      try {
        this.loadingText = "Updating user data. Please wait...";
        const toUpdate = { ...this.updatedFields };
        if (Object.keys(toUpdate) && Object.keys(toUpdate).length === 0) return;
        const updates = omit(toUpdate, ["isAtlasAdmin"]);

        await this.updateUser({
          id: this.userId,
          update: updates
        });
        this.$appNotifySuccess("User updated successfully");
        if (!beforeRouteLeave) {
          const currentUserRole = get(this.$getCurrentUser, "role", "");
          const allowedRoles = ["admin", "agencyAdmin", "subAdmin"];
          const path = currentUserRole === "admin" ? "admin" : "agencyAdmin";
          const redirectURL = allowedRoles.includes(currentUserRole)
            ? `/${path}/users`
            : "/";

          this.$router.push(redirectURL).catch(() => {});
        }
      } catch (e) {
        this.$emit("setError", e.message);
        this.$bugSnagClient.notify(e);
      } finally {
        this.$emit("scrollTop");
        this.loadingText = "";
      }
    },
    async beforeRouteLeaveSave() {
      try {
        this.$modal.hide("discard-changes-modal");
        if (!this.disablePrimaryButton) {
          await this.update(true);
          this.nextRoute();
        }
      } catch (error) {
        this.$bugSnagClient.notify(error);
        this.nextRoute(false);
      }
    },
    async beforeRouteLeaveDiscardChanges() {
      try {
        this.$modal.hide("discard-changes-modal");
        this.nextRoute();
      } catch (error) {
        this.$bugSnagClient.notify(error);
        this.nextRoute(false);
      }
    },
    activateUser() {
      this.editField({ key: "status", value: "active" });
      this.update();
    },
    async changePassword(): Promise<void> {
      const updatedPassword = await (this as any).$refs.passwordComponent.save();
      const data = { password: updatedPassword };
      try {
        this.loadingText = "Updating user password. Please wait...";
        await this.updateUser({
          id: this.userId,
          update: data
        });
        this.$emit("setMessage", "Password updated successfully");
        this.$modal.hide("changePasswordModal");
      } catch (e) {
        this.$emit("setError", e.message);
        this.$bugSnagClient.notify(e);
      } finally {
        this.loadingText = "";
      }
    },
    viewPassword() {
      this.$modal.show("changePasswordModal");
    },
    setDisabled(disable: any) {
      this.disabled = !!disable;
    },
    async deleteCallback(): Promise<any> {
      try {
        this.modalTitle = "Delete User";
        this.modalDescription = "This will delete the user. Continue?";
        this.actionType = "delete";
        this.$modal.show("showModal");
      } catch (e) {
        // Do nothing, user clicked cancel
      }
    },
    async unbanCallback(): Promise<any> {
      try {
        this.modalTitle = "Unban User";
        this.modalDescription =
          "This will restore all of the user's previous permissions. Continue?";
        this.actionType = "unban";
        this.$modal.show("showModal");
      } catch (e) {
        // Do nothing, user clicked cancel
      }
    },
    async restrictCallback(): Promise<any> {
      try {
        this.modalTitle = "Restrict User";
        this.modalDescription = "This will restrict user. Continue?";
        this.actionType = "restrict";
        this.$modal.show("showModal");
      } catch (e) {
        // Do nothing, user clicked cancel
      }
    },
    async destrictCallback(): Promise<any> {
      try {
        this.modalTitle = "Derestrict User";
        this.modalDescription =
          "This will restore all of the user's previous restrictions. Continue?";
        this.actionType = "derestrict";
        this.$modal.show("showModal");
      } catch (e) {
        // Do nothing, user clicked cancel
      }
    },
    validationChangeHandler(e: any) {
      const { formIsValid } = e;
      const formIsEdited = Object.keys(this.updatedFields).length > 0;
      this.disablePrimaryButton =
        !(formIsValid && formIsEdited) || this.makingApiRequest;
    },
    resetModalData() {
      this.$modal.hide("showModal");
      this.modalTitle = "";
      this.modalDescription = "";
      this.actionType = "";
      this.loading = false;
    },
    async handleAction(this: any) {
      switch (this.actionType) {
        case "delete":
          await this.userDelete(this.userId);
          break;
        case "unban":
          await this.unbanSingleUser(this.userId);
          break;
        case "restrict":
          await this.restrictSingleUser(this.userId);
          break;
        case "derestrict":
          await this.deRestrictSingleUser(this.userId);
          break;
        default:
          break;
      }
      this.redirectCurrentUser();
    },
    redirectCurrentUser() {
      if (
        this.$getCurrentUser &&
        this.$getCurrentUser.role &&
        this.$getCurrentUser.role === "admin"
      ) {
        this.$router.push("/admin/users").catch(() => {});
      } else {
        this.$router.push("/agencyAdmin/users").catch(() => {});
      }
    }
  },
  computed: {
    ...userMapGetters(["getEditingUser", "getUserById"]),
    ...authMapGetters(["isCurrentUserAdmin"]),
    updatedFields(): any {
      const edited = omit(this.getEditingUser, ["isAtlasAdmin"]);
      const original = this.getUserById(this.userId);
      return edited && original
        ? objectDifference(edited, original, [
            "mailingAddress.unitNumber",
            "physicalAddress.unitNumber",
            "mailingAddress.streetType",
            "physicalAddress.streetType",
            "mailingAddress.streetDirection",
            "physicalAddress.streetDirection",
            ...(this.getEditingUser &&
            (this.getEditingUser.role === "admin" ||
              this.getEditingUser.role === "ezlynx" ||
              this.getEditingUser.role === "adjuster")
              ? ["agencyId"]
              : []),
            ...(this.getEditingUser && this.getEditingUser.role !== "adjuster"
              ? ["adjustingFirm", "adjusterCode"]
              : []),
            ...(this.getEditingUser &&
            !(
              this.getEditingUser.role === "agent" ||
              this.getEditingUser.role === "agencyAdmin" ||
              this.getEditingUser.role === "subAdmin"
            )
              ? ["agentCode", "licenseNumber", "licenseNumberExpirationDate"]
              : [])
          ])
        : {};
    },
    original(): any {
      return this.getUserById(this.userId);
    },
    showPhysicalAddress(): boolean {
      if (
        this.getEditingUser &&
        this.getEditingUser.physicalAddress &&
        this.getEditingUser.physicalAddress.city !== "" &&
        this.getEditingUser.physicalAddress.houseNumber !== undefined &&
        this.getEditingUser.physicalAddress.streetName !== ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    showMailingAddress(): boolean {
      if (
        this.getEditingUser &&
        this.getEditingUser.mailingAddress &&
        this.getEditingUser.mailingAddress.city !== "" &&
        this.getEditingUser.mailingAddress.houseNumber !== undefined &&
        this.getEditingUser.mailingAddress.streetName !== ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    agentSelected(): boolean {
      return this.getEditingUser?.role == "agent" ? true : false;
    },
    addNewUserFields(): FormBlock[] {
      if (
        this.$isCurrentUserAdmin ||
        this.$isCurrentUserAgencyAdmin ||
        this.$isCurrentUserUnderwriter ||
        this.$isCurrentUserClaims ||
        this.$isCurrentUserBilling ||
        this.$isCurrentUserDataEntry ||
        this.$isCurrentUserSubAdmin
      ) {
        const disableForm: boolean =
          !this.$isCurrentUserAdmin &&
          !this.$isCurrentUserAgencyAdmin &&
          !this.$isCurrentUserSubAdmin;
        return adminUser({
          showPhysicalAddress: this.showPhysicalAddress,
          showMailingAddress: this.showMailingAddress,
          agentSelected: this.agentSelected,
          disableAgencyForm: disableForm,
          agentName: `${this.getEditingUser?.displayName}`,
          role: this.getEditingUser?.role,
          agencySelected: this.getEditingUser?.agencyId,
          isUserBanned: this.isUserBanned
        });
      } else {
        return agencyAdminUser;
      }
    },
    isUserBanned(): boolean {
      return this.getEditingUser?.isBanned as boolean;
    },
    userId(): string {
      return this.getEditingUser?._id || "";
    }
  },
  watch: {
    // "getEditingUser.role": {
    //   handler(newRole: string) {
    //     const formFields = getFieldsFromBlocks(
    //       adminUser(
    //         this.showPhysicalAddress,
    //         this.showMailingAddress,
    //         this.agentSelected
    //       )
    //     );
    //     // const agentCode = formFields.find(
    //     //   (field: any) => field.id === "field_agentCode"
    //     // );
    //     // if (agentCode) {
    //     //   if (newRole === "admin") {
    //     //     agentCode.properties.required = false;
    //     //   } else {
    //     //     agentCode.properties.required = true;
    //     //   }
    //     //   this.customKey = this.customKey + 1;
    //     // }
    //   },
    //   deep: true,
    //   immediate: true
    // },
    disablePrimaryButton() {
      this.$emit("formIsValid", this.disablePrimaryButton);
    }
  }
});
